import React, { useState, useEffect } from 'react';
import './RequestRegistration.css';
import registrationIllustration from '../../assets/images/Sign up-cuate.png';
import { toast, ToastContainer } from 'react-toastify';

const RequestRegistration = ({ apiClient }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone_no: '',
    company: '',
    title: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [verificationData, setVerificationData] = useState({ email: '', token: '' });

  // Load email from local storage on component mount
  useEffect(() => {
    const storedEmail = localStorage.getItem('user_email');
    if (storedEmail) {
      setVerificationData((prevData) => ({ ...prevData, email: storedEmail }));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleVerificationInputChange = (e) => {
    const { name, value } = e.target;
    setVerificationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!formData.email.includes('@')) {
      toast.error('Invalid email address.', { position: 'bottom-right' });
      return false;
    }
    if (formData.password.length < 8) {
      toast.error('Password must be at least 8 characters.', { position: 'bottom-right' });
      return false;
    }
    return true;
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);

    const { first_name, last_name, email, password, phone_no,country, company, title } = formData;

    try {
      const response = await apiClient.requestRegistration({
        first_name,
        last_name,
        email,
        password,
        phone_no,
        country,
        company,
        title,
      });

      if (response.error) {
        toast.error(response.error, { position: 'bottom-right' });
      } else {
        toast.success('Registration successful! Please verify your email.', { position: 'bottom-right' });

        // Save email to local storage
        localStorage.setItem('user_email', formData.email);

        // Automatically open verification modal
        setVerificationData((prevData) => ({ ...prevData, email: formData.email }));
        setIsVerificationModalOpen(true);
      }
    } catch (err) {
      console.error('Registration error:', err);
      toast.error('An error occurred. Please try again later.', { position: 'bottom-right' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify = async () => {
    const { email, token } = verificationData;

    if (!email || !token) {
      toast.error('Both email and token are required.', { position: 'bottom-right' });
      return;
    }

    try {
      const response = await fetch(`${apiClient.endpoint}/api/auth/verify-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, token }),
      });

      if (response.ok) {
        toast.success('Verification successful!', { position: 'bottom-right' });
        setIsVerificationModalOpen(false);
      } else {
        const error = await response.json();
        toast.error(error.message || 'Verification failed.', { position: 'bottom-right' });
      }
    } catch (err) {
      console.error('Verification error:', err);
      toast.error('An error occurred. Please try again later.', { position: 'bottom-right' });
    }
  };




  // const handleResendToken = async () => {
  //   const email = localStorage.getItem('signup_email');

  //   try {
  //     const response = await apiClient.resendVerificationToken({ email });

  //     if (response.error) {
  //       toast.error(response.error, { position: 'bottom-right' });
  //     } else {
  //       toast.success('Verification token resent! Please check your email.', { position: 'bottom-right' });
  //     }
  //   } catch (err) {
  //     console.error('Resend Token error:', err);
  //     toast.error('An error occurred while resending the token. Please try again.', { position: 'bottom-right' });
  //   }
  // };



  const handleResendToken = async () => {
    const { email } = verificationData;

    if (!email) {
      toast.error('Please enter your email address to resend the token.', { position: 'bottom-right' });
      return;
    }

    try {
      const response = await fetch(`${apiClient.endpoint}/api/auth/resend-token?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        toast.success('Token resent successfully! Please check your email.', { position: 'bottom-right' });
        setIsResendModalOpen(false);
      } else {
        const error = await response.json();
        toast.error(error.message || 'Failed to resend the token.', { position: 'bottom-right' });
      }
    } catch (err) {
      console.error('Resend token error:', err);
      toast.error('An error occurred. Please try again later.', { position: 'bottom-right' });
    }
  };

  return (
    <div className="request-registration-container">
      <ToastContainer />
      <div className="image-half">
        <img src={registrationIllustration} alt="Registration Illustration" className="registration-image" />
      </div>
      <div className="form-half">
        <div className="form-content">
          <h2>Sign Up</h2>
          <form onSubmit={handleRegistration}>
            <div className="row">
              <input
                type="text"
                placeholder="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
                className="half-width-input"
              />
              <input
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
                className="half-width-input"
              />
            </div>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="input-field"
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
              className="input-field"
            />
            <input
              type="text"
              placeholder="Phone Number"
              name="phone_no"
              value={formData.phone_no}
              onChange={handleInputChange}
              required
              className="input-field"
            />
            <input
              type="text"
              placeholder="Company"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              required
              className="input-field"
            />
            {/* <input
              type="text"
              placeholder="Country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              required
              className="input-field"
            /> */}
            <input
              type="text"
              placeholder="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              className="input-field"
            />
            <button type="submit" disabled={isLoading} className="primary-button">
              {isLoading ? 'Registering...' : 'Register'}
            </button>
          </form>
          <p className="resend-token-text" onClick={() => setIsResendModalOpen(true)}>
            Resend Token
          </p>
          <p className="resend-token-text" onClick={() => setIsVerificationModalOpen(true)}>
            Open Verification Modal
          </p>
        </div>
      </div>

      {/* Verification Modal */}
      {isVerificationModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Email Verification</h3>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={verificationData.email}
              onChange={handleVerificationInputChange}
              className="input-field"
              readOnly
            />
            <input
              type="text"
              placeholder="Token"
              name="token"
              value={verificationData.token}
              onChange={handleVerificationInputChange}
              className="input-field"
            />
            <button onClick={handleVerify} className="primary-button">
              Verify
            </button>
            <button onClick={() => setIsVerificationModalOpen(false)} className="secondary-button">
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Resend Token Modal */}
      {isResendModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Resend Token</h3>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={verificationData.email}
              onChange={handleVerificationInputChange}
              className="input-field"
            />
            <button onClick={handleResendToken} className="primary-button">
              Resend
            </button>
            <button onClick={() => setIsResendModalOpen(false)} className="secondary-button">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestRegistration;
