import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa"; // Import search icon
import "./SearchBar.css";

const SearchBar = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      onSearch(searchQuery);
    }, 500);
    return () => clearTimeout(delaySearch);
  }, [searchQuery, onSearch]);

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />
      <button className="search-button">
        <FaSearch size={16} />
      </button>
    </div>
  );
};

export default SearchBar;
