import React, { useState, useRef, useEffect } from "react";
import { FaTimes, FaPaperPlane } from "react-icons/fa"; // Importing icons
import "./Chatbot.css";

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false); // Initialize as closed by default
  const [messages, setMessages] = useState([
    { type: "bot", text: "Hello! How can I assist you today?" },
  ]);
  const [input, setInput] = useState(""); // State for input

  const messagesEndRef = useRef(null); // Ref for scrolling to the latest message

  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
    }
  }, [messages, isOpen]);

  const toggleChatbot = () => setIsOpen(!isOpen);

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = { type: "user", text: input };
    setMessages((prev) => [...prev, userMessage]);

    try {
      const botResponse = await getBotResponse(input);
      const botMessage = { type: "bot", text: botResponse };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        { type: "bot", text: "Please reach out to sales@hypothesisbase.com further inquiry." },
      ]);
    }

    setInput(""); // Clear input field
  };

  const getBotResponse = async (userInput) => {
    const response = await fetch("http://localhost:8000/chat", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user_input: userInput }),
    });

    if (!response.ok) throw new Error("Failed to fetch response from server.");

    const data = await response.json();
    return data.response;
  };

  return (
    <div className="chatbot-wrapper">
      {isOpen ? (
        <div className="chatbot-container">
          {/* Header */}
          <div className="chatbot-header">
            <span className="chatbot-title">AI Agent</span>
            <button className="icon-btn close-btn" onClick={toggleChatbot}>
              <FaTimes />
            </button>
          </div>

          {/* Messages */}
          <div className="chatbot-content">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`chat-message ${
                  message.type === "bot" ? "bot-message" : "user-message"
                }`}
              >
                {message.text}
              </div>
            ))}
            <div ref={messagesEndRef}></div> {/* Ref for auto-scrolling */}
          </div>

          {/* Input Section */}
          <div className="chatbot-input">
            <input
              className="chatbot-textbox"
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your question here..."
            />
            <button className="icon-btn send-btn" onClick={handleSendMessage}>
              <FaPaperPlane />
            </button>
          </div>
        </div>
      ) : (
        <button className="chatbot-toggle-btn" onClick={toggleChatbot}>
          💬 Chat
        </button>
      )}
    </div>
  );
};

export default ChatBot;
