import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify styles
import './Login.css';
import loginIllustration from '../../assets/images/Mobile login-cuate.png';

const Login = ({ apiClient }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const verify = async () => {
      try {
        const response = await apiClient.validateToken(accessToken);
        if (!response.error) {
          navigate('/view-profile');
        }
      } catch {
        setError('Session expired. Please login again.');
        localStorage.removeItem('accessToken');
      }
    };

    if (accessToken) {
      verify();
    }
  }, [apiClient, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await apiClient.login(email, password);
      localStorage.setItem('accessToken', response.accessToken);

      // Show toast message for successful login
      toast.success(`${response.userName || 'User'} logged in successfully!`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate('/home');
    } catch {
      setError('Invalid email or password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <ToastContainer /> {/* Toast container to display messages */}
      <div className="image-half">
        <img
          src={loginIllustration}
          alt="Illustration of mobile login"
          className="login-image"
        />
      </div>
      <div className="form-half">
        <div className="form-content">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Password"
            />
            {error && <div className="error-message">{error}</div>}
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>
          <div className="additional-options">
            <a href="/request-reset-pw" className="alt-link">Forgot Password?</a>
            <a href="/request-registration" className="alt-link">Sign Up</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
