

import React from "react";

import "./Purchase.css";

const Purchase = () => {
  
  const plans = [
    {
      id: 1,
      name: "Basic Plan",
      description: "Ideal for small-scale experiments and personal projects.",
      price: "$10/month",
      features: ["1 System Controller", "Basic Support", "10GB Storage"],
    },
    {
      id: 2,
      name: "Pro Plan",
      description: "Best for medium-sized teams and advanced use cases.",
      price: "$15/month",
      features: [
        "5 System Controllers",
        "Priority Support",
        "50GB Storage",
        "Advanced Analytics",
      ],
    },
    {
      id: 3,
      name: "Enterprise Plan",
      description: "Customized solutions for large organizations.",
      price: "$20/month",
      features: [
        "Unlimited Controllers",
        "24/7 Dedicated Support",
        "Unlimited Storage",
        "Custom Integrations",
      ],
    },
  ];

  return (
    <div className="purchase-container">
      <h2 className="header2">Our Subscription Plans</h2>
      <h1>Choose the Best Plan for Your Needs</h1>
      <div className="purchase-cards">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className={`purchase-card ${plan.id === 2 ? "highlight" : ""}`}
          >
            <h2 className="plan-name">{plan.name}</h2>
            <p className="plan-price">{plan.price}</p>
            <p className="plan-description">{plan.description}</p>
            <ul className="plan-features">
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <button className="btn">Select Plan</button>
          </div>
        ))}
      </div>
      {/* <div>hhjhjhjhhjj</div> */}
    </div>
  );
};

export default Purchase;

// jjj