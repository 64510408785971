import React, { useState } from "react";
import "./Contactaccord.css"; // Importing the CSS file

import SubmitInquiry from "../SubmitInquiry";

const Contactaccord = () => {
  // State to track the open question
  const [openIndex, setOpenIndex] = useState(null);

  // Accordion items with questions and answers
  const items = [
    {
      question: "Why use the HypothesisBase Systems Controller?",
      answer:
        "The HBSC provides the greatest ease of setup and ease of use, while providing an intuitive UI for running workflows and managing services.",
    },
    {
      question: "What can I use the HypothesisBase Systems Controller for?",
      answer:
        "There are many use cases for the HBSC, including: CI/CD automation, job scheduling, system observability and more.",
    },
    {
      question: "How do I set up the HypothesisBase Systems Controller?",
      answer:
        "A lightweight executable is ran on managed nodes, and a control node executable runs operations on managed nodes through a secure TCP connection.",
    },
    {
      question: "Which platforms are supported by the HypothesisBase Systems Controller?",
      answer:
        "HypothesisBase Systems Controller works across infrastructure(s) (cloud-based, on-premise, hybrid) and across operating systems (Windows, Linux, MacOS).",
    },
  ];

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      <h2 className="header2">Contact Us</h2>
      <div className="conflex">
        <div className="s">
          <h5 className="ach3">Want to ask something?</h5>
          <SubmitInquiry />
        </div>

        <div className="accord">
          <h4>Frequently Asked Questions</h4>

          {items.map((item, index) => (
            <div className="accordion-item" key={index}>
              <button
                className={`accordion-header ${openIndex === index ? "active" : ""}`}
                onClick={() => toggleItem(index)}
                aria-expanded={openIndex === index}
              >
                <span className="accordion-question">{item.question}</span>
                <span
                  className={`accordion-arrow ${openIndex === index ? "open" : ""}`}
                >
                  {openIndex === index ? "▲" : "▼"}
                </span>
              </button>
              <div
                className={`accordion-content ${openIndex === index ? "visible" : ""}`}
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contactaccord;
