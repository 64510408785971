import React from 'react';
import './Home.css';
import Title from './homepage/Title';
import Introduction from './introduction/Introduction';
// import VantaIntroduction from './introduction/VantaIntroduction';
import Affiliations from './homepage/Affiliations';
import Methodology from './homepage/Methodology';
import Solutions from './homepage/Solutions';
import Offerings from './offerings/Offerings';
import FadeInView from '../helper/FadeInView';
import Plans from './homepage/Plans';
import Contactaccord from './homepage/Contactaccord';
import SlideLeftView from '../helper/SlideLeftView';
import FeatureComparisonTable from './offerings/FeatureComparisonTable';
import TestimonialSlider from './homepage/TestimonialSlider';
import SystemsController from './homepage/SystemsController';
// import Setuplist from './SystemsController/Setuplist';
import ChatBot from './Chat/Chatbot'; // Import the ChatBot component

const Home = () => {
  return (
    <div className="home-container">
      <FadeInView threshold={0.2}>
        <Introduction />
        <SystemsController />
        <FeatureComparisonTable />
        {/* <Setuplist /> */}
      </FadeInView>
      <FadeInView threshold={0.2}>
        <Methodology />
      </FadeInView>
      <FadeInView threshold={0.2}>
        <Contactaccord />
      </FadeInView>
      {/* ChatBot Section */}
      <FadeInView threshold={0.2}>
        <div className="chatbot-section">
          <ChatBot />
        </div>
      </FadeInView>
    </div>
  );
};

export default Home;
