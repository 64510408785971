import React, { useEffect, useState ,useRef} from "react";
import { useNavigate } from "react-router-dom";
import "./Introduction.css";
import TypingText from "../../helper/TypingText";
import ScrollReveal from "scrollreveal";
// import * as THREE from "three";
// import NET from "vanta/src/vanta.net"; 
import hypothesisbaseLogo from '../../../../assets/Logo/hypothesisbase.webp'; // Adjust the path if necessary

const Introduction = (props) => {
  const navigate = useNavigate();
  const vantaRef = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(null);

  const handleSearch = (e) => {
    navigate("systems-controller");
    return;
  };
  const message =
    "With our Universal Shell software, automation becomes effortless, freeing up time and resources for continuous innovation and advancement.";
  const typedMessage = "Easily Deploy & Manage Software Systems";
  // const message = "Enterprises can optimize their workforce by automating repetitive tasks through a reliable automation pipeline, freeing up employees to focus on high-impact, innovative projects. By combining human ingenuity with efficient automation, organizations can drive continuous improvement, enhance productivity, and stay ahead in today's dynamic business landscape."

  // useEffect(() => {

  //   if (!vantaEffect) {
  //     setVantaEffect(
  //       NET({
  //         el: vantaRef.current,
  //         THREE,
  //         mouseControls: true,
  //         touchControls: true,
  //         gyroControls: false,
  //         minHeight: 200.00,
  //         minWidth: 200.00,
  //         scale: 1.0,
  //         scaleMobile: 1.0,
  //         color: 0xff0707,

  //       })
  //     );
  //   }

  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy(); // Cleanup effect on unmount
  //   };
  // }, [vantaEffect]);

  // Function to dynamically load a script
    const loadScript = (src, callback) => {
      console.log(`Loading script: ${src}`);
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = () => {
        console.log(`Loaded: ${src}`);
        callback();
      };
      document.body.appendChild(script);
    };

    useEffect(() => {
      if (!vantaRef.current) return; // Ensure ref is ready

      loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js",
        () => {
          loadScript(
            "https://cdnjs.cloudflare.com/ajax/libs/vanta/0.5.24/vanta.net.min.js",
            () => {
              if (!vantaEffect && window.VANTA) {
                console.log("Initializing Vanta...");
                setVantaEffect(
                  window.VANTA.NET({
                    el: vantaRef.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.0,
                    minWidth: 200.0,
                    scale: 1.0,
                    scaleMobile: 1.0,
                    color: 0xffffff,
                  })
                );
              }
            }
          );
        }
      );

      return () => {
        if (vantaEffect) {
          console.log("Destroying Vanta effect...");
          vantaEffect.destroy();
        }
      };
    }, [vantaRef]);

  useEffect(() => {
    ScrollReveal().reveal(".introduction_h1", {
      delay: 350,
      origin: "bottom",
      duration: 1000,
      distance: "50px",
      opacity: 0,
      easing: "ease-in-out",
      // reset: true,
    });

    ScrollReveal().reveal(".cloud-text", {
      delay: 700,
      duration: 300,
      origin: "right",
      distance: "50px",
      opacity: 0,
      easing: "ease-in-out",
     
    });

    ScrollReveal().reveal(".cloud-logos", {
      delay: 1000,
      duration: 350,
      distance: "50px",
      opacity: 0,
      easing: "ease-in-out",
      
    });
  }, []);

  return (
    <>
      <section ref={vantaRef} className="introduction_div">
        <div className="introduction_container">
          <h1 className="introduction_h1">
            <img src={hypothesisbaseLogo} alt="HypothesisBase Logo" />
          </h1>
          <div className="introduction_text">
            <TypingText text={typedMessage} typingSpeed={40} />
          </div>
          {/* added by me */}
          <div
            style={{
              position: "relative",
              width: "fit-content",
              marginInline: "auto",
              marginBottom: "20px",
              // height:'400px',
              // top: "-15%",

              // border:'2px solid red'
            }}
          >
            <img style={{}} src="mockupnew.png" alt="mockup" />
          </div>
          {/*  */}

          {/* <section style={{ background: "black", width: "100%" }}></section> */}
          {/* <button className="introduction-search-button" onClick={handleSearch}>
            Sign up
          </button> */}
          <button className="introduction-search-button" onClick={handleSearch}>
            View our Systems Controller
          </button>
          <div className="cloud-div">
            <p className="cloud-text">
              {/* We simplify the deployment and management of software systems on
              AWS, Azure, GCP, and on-premise environments. */}
            </p>
          </div>
        </div>
      </section>
      {/* <div>hfjnjfnjflfnlf</div> */}
    </>
  );
};

export default Introduction;
