
import React from "react";
import "./UserInfo.css";

const UserInfo = () => {
  const userModel = {
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "(+1) 234-567-8901",
    emailAddress: "john.doe@example.com",
    Company:'space x',
    description:"Experienced software developer with a passion for building scalable and efficient web applications.",
    gender: "Male",
    location: "New York, USA",
    postalCode: "10001",
  };

  const {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    description,
    Company,
    gender,
    location,
    postalCode,
  } = userModel;

  return (
    <div className="user-info">
      <aside className="sidebar">
        <h2>User Info</h2>
        <ul className="menu">
          <li className="menu-item active">User Info</li>
          {/* Additional menu items can be added here */}
        </ul>
        <div className="logout">Log Out</div>
        
      </aside>

      <main className="info-content">
        <header className="info-header">
          <div className="info-picture">
            <img
              src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
              alt={`${firstName} ${lastName}'s Avatar`}
              className="info-avatar"
            />
          </div>
          <h2>{`${firstName} ${lastName}`}</h2>
          <p>{location}</p>
        </header>

        <div className="info-details">
          <p>
            <strong>Email Address:</strong> {emailAddress}
          </p>
          <p>
            <strong>Phone Number:</strong> {phoneNumber}
          </p>
          <p>
            <strong>Company:</strong> {Company}
          </p>
          <p>
            <strong>Location:</strong> {location}
          </p>
          <p>
            <strong>Postal Code:</strong> {postalCode}
          </p>
          <p>
            <strong>Gender:</strong> {gender}
          </p>

          <p>
            <strong>Description:</strong> {description}
          </p>
        </div>
      </main>
    </div>
  );
};

export default UserInfo;

// jjj