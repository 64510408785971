import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import AuthDropdown from "./helper/AuthDropdown";
import "./Header.css";

const Header = ({ apiClient }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const headerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const validateUser = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        setAuthenticated(false);
        return;
      }

      try {
        const response = await apiClient.validateToken(accessToken);
        setAuthenticated(!!response?.success);
        setEmail(response?.success?.email || "User");
      } catch (error) {
        console.error("Error validating token:", error);
        setAuthenticated(false);
      }
    };

    validateUser();
  }, [apiClient.validateToken]);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => !prev);
    setOpenDropdown(null);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
    setOpenDropdown(null);
  }, []);

  const handleSignOut = useCallback(() => {
    localStorage.removeItem("accessToken");
    setAuthenticated(false);
    closeMenu();
  }, [closeMenu]);

  const handleDropdownToggle = useCallback((dropdown) => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  }, []);

  // Detect screen size changes and reset dropdown state
  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth <= 768;
      setIsMobile(mobileView);

      // Close dropdowns when switching from mobile to desktop
      if (!mobileView) {
        setOpenDropdown(null);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (menuOpen || openDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuOpen, openDropdown, closeMenu]);

  return (
    <header className="header" ref={headerRef}>
      <div className="left">
        <Logo closeMenu={closeMenu} />
        <Navigation
          authenticated={authenticated}
          menuOpen={menuOpen}
          closeMenu={closeMenu}
          openDropdown={openDropdown}
          handleDropdownToggle={handleDropdownToggle}
          isMobile={isMobile}
        />
      </div>
      <div className="right">
        {authenticated && <AuthDropdown email={email} handleSignOut={handleSignOut} />}
        <button
          className="hamburger-menu"
          onClick={toggleMenu}
          aria-label="Toggle navigation"
          aria-expanded={menuOpen}
        >
          <span className="hamburger-icon"></span>
        </button>
      </div>
    </header>
  );
};

// Extracted Components
const Logo = ({ closeMenu }) => (
  <div className="logo-container">
    <Link to="/" onClick={closeMenu}>
      <img src="/hb_logo.png" alt="Logo" className="logo" />
    </Link>
  </div>
);

const Navigation = ({
  authenticated,
  menuOpen,
  closeMenu,
  openDropdown,
  handleDropdownToggle,
  isMobile,
}) => (
  <nav className={`link-container ${menuOpen ? "active" : ""}`}>
    <div className="fle">
      <Dropdown
        title="Products"
        options={[
          { label: "Systems Controller", value: "/systems-controller" },
          { label: "Infrastructure Manager for AWS", value: "/infrastructure-manager-for-aws" },
          // { label: "LLM Integrations", value: "/LLM-Integrations" },
          // { label: "Data Warehousing & Integration", value: "/Data-Warehousing-Integration" },
        ]}
        isOpen={openDropdown === "solution"}
        toggleDropdown={() => handleDropdownToggle("solution")}
        closeMenu={closeMenu}
        isMobile={isMobile}
      />
      <Dropdown
        title="Documentation"
        options={[
          { label: "Universal Shell", value: "/universal-shell" },
          { label: "AWS Extended Shell", value: "/universal-shell-aws" },
          { label: "AWS Infrastructure Builder", value: "/aws-infra-builder" },
        ]}
        isOpen={openDropdown === "services"}
        toggleDropdown={() => handleDropdownToggle("services")}
        closeMenu={closeMenu}
        isMobile={isMobile}
      />
      <Link to="/search-jobs" className="nav-link" onClick={closeMenu}>
        Jobs
      </Link>
    </div>
    {!authenticated && (
      <div className="contact">
        <Link to="/submit-inquiry" className="nav-link" onClick={closeMenu}>
          Contact Us
        </Link>
      </div>
    )}
  </nav>
);

const Dropdown = ({ title, options, isOpen, toggleDropdown, closeMenu, isMobile }) => {
  const dropdownRef = useRef(null);

  // Close only the dropdown when clicking outside
  const handleClickOutside = useCallback(
    (event) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(null);
      }
    },
    [isOpen, toggleDropdown]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen, handleClickOutside]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        className="nav-link dropdown-toggle"
        onClick={() => toggleDropdown(isOpen ? null : title.toLowerCase())}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {title} &nbsp;
        <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
      </button>

      {isOpen && (
        <ul className={`dropdown-menu ${isMobile ? "mobile-dropdown" : ""}`}>
          {options.map((option) => (
            <li key={option.value} className="dropdown-item" onClick={closeMenu}>
              <Link to={option.value} className="nav-link">
                {option.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Header;
