import React from 'react';
import './FeatureComparisonTable.css';

// Import logos dynamically
import hypothesisbaseLogo from '../../../../assets/Logo/hypothesisbase.png';
import terraformLogo from '../../../../assets/Logo/terraform.png';
import ansibleLogo from '../../../../assets/Logo/ansible.png';
// import suseLogo from '../../../../assets/Logo/chef.png';
import suseLogo from '../../../../assets/Logo/suse.png';
import puppetLogo from '../../../../assets/Logo/puppet.png';

// Import PNG icons for Features
import portabilityIcon from '../../../../assets/icons/portability.png';
import observabilityIcon from '../../../../assets/icons/observability.png';
import apiCoverageIcon from '../../../../assets/icons/api_coverage.png';
import apiSimplicityIcon from '../../../../assets/icons/api_simplicity.png';
import pricingIcon from '../../../../assets/icons/pricing.png';
import { GreenCheck, GreyCheck, RedXMark, DollarIcon, GreyDollarIcon } from './StatusIcons';

// Tools and Feature Lists
const tools = [
  { name: 'Systems Controller', logo: hypothesisbaseLogo },
  { name: 'Ansible', logo: ansibleLogo },
  { name: 'Rancher', logo: suseLogo },
  { name: 'Terraform', logo: terraformLogo },
  { name: 'Puppet', logo: puppetLogo },
];

// Feature Object for easier editing (product, feature mapping)
const features = {
  portability: {
    name: 'Portability',
    icon: portabilityIcon,
  },
  observability: {
    name: 'Observability',
    icon: observabilityIcon,
  },
  apiCoverage: {
    name: 'API Coverage',
    icon: apiCoverageIcon,
  },
  apiSimplicity: {
    name: 'API Simplicity',
    icon: apiSimplicityIcon,
  },
  pricing: {
    name: 'Pricing',
    icon: DollarIcon, // The icon to be used for pricing
  },
};

// Ratings object: (tool, feature) => rating
const ratings = {
  'Systems Controller-portability': 3,
  'Systems Controller-observability': 3,
  'Systems Controller-apiCoverage': 3,
  'Systems Controller-apiSimplicity': 3,
  'Systems Controller-pricing': 1,

  'Terraform-portability': 2,
  'Terraform-observability': 1,
  'Terraform-apiCoverage': 2,
  'Terraform-apiSimplicity': 3,
  'Terraform-pricing': 2,

  'Ansible-portability': 2,
  'Ansible-observability': 1,
  'Ansible-apiCoverage': 3,
  'Ansible-apiSimplicity': 2,
  'Ansible-pricing': 3,

  'Rancher-portability': 2,
  'Rancher-observability': 3,
  'Rancher-apiCoverage': 3,
  'Rancher-apiSimplicity': 2,
  'Rancher-pricing': 2,

  'Puppet-portability': 2,
  'Puppet-observability': 2,
  'Puppet-apiCoverage': 3,
  'Puppet-apiSimplicity': 1,
  'Puppet-pricing': 3,
};

const FeatureComparisonTable = () => {
  const getCheckmarks = (score) => {
    if (score === -1) return <RedXMark />;
    const checkmarks = [];
    for (let i = 0; i < score; i++) {
      checkmarks.push(<GreenCheck key={`green-${i}`} />);
    }
    // Optionally add a grey check if there's a partial score like 1 or 2.
    for (let i = 0; i < 3 - score; i++) {
      checkmarks.push(<GreyCheck key={`grey-${i + score}`} />);
    }
    return checkmarks;
  };

  const getDollarIcons = (score) => {
    const icons = [];
    for (let i = 0; i < score; i++) {
      icons.push(<DollarIcon key={`dollar-${i}`} />);
    }
    for (let i = 0; i < 3 - score; i++) {
      icons.push(<GreyDollarIcon key={`dollar-${i + score}`} />);
    }
    return icons;
  };

  return (
    <div className="table-container">
      <h2 className="title">HypothesisBase Systems Controller Comparison Chart</h2>
      <div>
        
      </div>
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            {tools.map((tool, index) => (
              <th
                key={index}
                className={tool.name === 'HypothesisBase' ? 'highlight-column' : ''}
              >
                <div className="tool-header">
                  <img
                    src={tool.logo}
                    alt={`${tool.name} logo`}
                    className="company-logo"
                  />
                  <span className="tool-name">{tool.name}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(features).map((featureKey) => {
            const feature = features[featureKey];
            return (
              <tr key={feature.name}>
                <td className="feature-name">
                  <span className="feature-text">{feature.name}</span>
                </td>
                {tools.map((tool, idx) => {
                  const rating = ratings[`${tool.name}-${featureKey}`] || -1; // Get rating or fallback to -1 if not defined
                  return (
                    <td key={idx} className={rating === -1 ? 'not-supported' : 'supported'}>
                      <div className="checkmarks-container">
                        {feature.name === 'Pricing' ? getDollarIcons(rating) : getCheckmarks(rating)}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FeatureComparisonTable;
