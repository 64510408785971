import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ViewJob.css';

const defaultJob = {
    "jobId": "",
    "jobTitle": "",
    "companyName": "",
    "postedDate": "",
    "jobLocation": "",
    "jobSalary": "",
    "jobDescription": "",
    "companyIconUrl": ""
}


const ViewJob = ({ apiClient }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const [jobPost, setJobPost] = useState(defaultJob);

  const accessToken = localStorage.getItem('accessToken');
  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobPost = async () => {
      try {
        setLoading(true);
        const jobPostData = await apiClient.view_job_post(accessToken, jobId);
        setJobPost(jobPostData);
        setLoading(false);
      } catch (error) {
        setError('Error fetching job post');
        setLoading(false);
      }
    };

    fetchJobPost();
  }, [apiClient, accessToken, jobId]);

  const handleApply = async () => {
    setError('');
    setSuccess('');
    if (!accessToken) {
      setError('Error: please sign in');
      return;
    }
    try {
      setLoading(true);
      await apiClient.apply_for_job(accessToken, jobId);
      setSuccess('Successfully applied for the job!');
    } catch (error) {
      setError(`Error: ${error.error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyResumeOnly = async () => {
    setError('');
    setSuccess('');
    if (resumeFile) {
      try {
        setLoading(true);
        await apiClient.apply_for_job_resume_only(resumeFile, resumeData, jobId);
        setSuccess('Successfully applied for the job with resume!');
      } catch (error) {
        setError('Error applying for the job with resume');
      } finally {
        setLoading(false);
      }
    } else {
      setError('Please upload a resume file.');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setResumeFile(file.name);
    const reader = new FileReader();

    reader.onload = async (e) => {
      const base64Resume = e.target.result;
      setResumeData(base64Resume);
    };

    reader.readAsDataURL(file);
    // console.log(file);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  var desc = '<div>Loading...</div>';
  if ( jobPost.jobDescription ) {
    desc = jobPost.jobDescription;
  }

  // const applySection = (
  //   <div className="apply-buttons">
  //       <div style={{ width: '40%', float: 'left' }}>
  //         <button className="apply-button" onClick={handleApply}>Apply to Job</button>
  //       </div>
  //       <div style={{ width: '40%', float: 'left' }}>
  //         <button className="apply-button" onClick={handleApplyResumeOnly}>Apply by Resume Only</button>
  //         <input type="file" onChange={handleFileChange} />
  //       </div>
  //   </div>
  // );

  const applySection = (
    <div className="apply-buttons">
        <div style={{ textAlign: 'center' }}>
          <button className="apply-button" onClick={handleApplyResumeOnly}>Apply by Resume Only</button>
          <input type="file" onChange={handleFileChange} />
        </div>
    </div>
  );

  return (
    <div className="view-job-container">
      <div className="message-container">
        {success && <div className="success-message">{success}</div>}
      </div>
      <div className="company-icon-container">
        <img src={jobPost?.companyIconUrl} alt="Company Icon" className="company-icon" />
      </div>
      <div className="job-details">
        <div className="title">
          <strong>{jobPost?.jobTitle} at {jobPost?.companyName}</strong>
        </div>
        <div className="info">
          <div><strong>Posted Date:</strong> {jobPost?.postedDate}</div>
          <div><strong>Job Location:</strong> {jobPost?.jobLocation}</div>
          <div><strong>Job Salary:</strong> {jobPost?.jobSalary}</div>
        </div>
        <div className="job-description">
          <hr />
          <div className="job-subtitle">Job Description</div>
          <div dangerouslySetInnerHTML={{ __html: jobPost?.jobDescription }} />
        </div>
        <hr />
        {applySection}
        <div className="message-container">
          {error && <div className="error-message">{error}</div>}
        </div>
        <button className="back-button" onClick={() => navigate('/search-jobs')}>Back to Job Search</button>
      </div>
    </div>
  );
};

ViewJob.propTypes = {
  apiClient: PropTypes.object.isRequired,
};

export default ViewJob;
