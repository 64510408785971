import React from "react";
import "./VideoEmbed.css"; // Import the CSS for responsiveness

const VideoEmbed = () => {
  return (
    <div className="video-container">
      <iframe
        src="https://www.youtube.com/embed/BXe4LYdIQGY"
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <a
        href="https://www.youtube.com/results?search_query=hypothesisbase"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="learn-m">Watch More</button>
      </a>
    </div>
  );
};

export default VideoEmbed;
