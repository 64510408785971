import React from 'react';

export const GreenCheck = () => (
  <svg
    fill="#77b300"
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
  </svg>
);

export const GreyCheck = () => (
  <svg
    fill="#C0C0C0"
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
  </svg>
);

export const RedXMark = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 9L15 15M15 9L9 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="red"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const GreyDots = () => (
  <svg
    fill="grey"
    width="30px"
    height="30px"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27.9999 51.9063C41.0546 51.9063 51.9063 41.0781 51.9063 28C51.9063 14.9453 41.0312 4.0937 27.9765 4.0937C14.8983 4.0937 4.0937 14.9453 4.0937 28C4.0937 41.0781 14.9218 51.9063 27.9999 51.9063ZM27.9999 47.9219C16.9374 47.9219 8.1014 39.0625 8.1014 28C8.1014 16.9609 16.914 8.0781 27.9765 8.0781C39.0155 8.0781 47.8983 16.9609 47.9219 28C47.9454 39.0625 39.039 47.9219 27.9999 47.9219ZM20.2655 28.0234C20.2655 26.3125 18.8124 24.8594 17.1014 24.8594C15.3671 24.8594 13.9374 26.3125 13.9374 28.0234C13.9374 29.7344 15.3905 31.1875 17.1014 31.1875C18.8124 31.1875 20.2655 29.7344 20.2655 28.0234ZM31.1405 28.0234C31.1405 26.3125 29.664 24.8594 27.9765 24.8594C26.2421 24.8594 24.8124 26.3125 24.8124 28.0234C24.8124 29.7344 26.289 31.1875 27.9765 31.1875C29.664 31.1875 31.1405 29.7344 31.1405 28.0234ZM42.0155 28.0234C42.0155 26.3125 40.5624 24.8594 38.8514 24.8594C37.1171 24.8594 35.664 26.3125 35.664 28.0234C35.664 29.7344 37.1405 31.1875 38.8514 31.1875C40.5624 31.1875 42.0155 29.7344 42.0155 28.0234Z" />
  </svg>
);

export const GreyXMark = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 9L15 15M15 9L9 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="grey"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DollarIcon = () => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M14.5 9C14.5 9 13.7609 8 11.9999 8C8.49998 8 8.49998 12 11.9999 12C15.4999 12 15.5 16 12 16C10.5 16 9.5 15 9.5 15"
          stroke="#edd400"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M12 7V17"
          stroke="#edd400"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          stroke="#edd400"
          strokeWidth="2"
        ></path>
      </g>
    </svg>
  );
};

export const GreyDollarIcon = () => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M14.5 9C14.5 9 13.7609 8 11.9999 8C8.49998 8 8.49998 12 11.9999 12C15.4999 12 15.5 16 12 16C10.5 16 9.5 15 9.5 15"
          stroke="#C0C0C0"  // Changed to grey
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M12 7V17"
          stroke="#C0C0C0"  // Changed to grey
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          stroke="#C0C0C0"  // Changed to grey
          strokeWidth="2"
        ></path>
      </g>
    </svg>
  );
};



// export { GreenCheck, RedXMark, GreyDots, GreyXMark };
