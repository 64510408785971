// import React, { useState } from 'react';

// import Affiliations from './homepage/Affiliations';
// import FadeInView from '../helper/FadeInView';
// import CompanyLogo from '../helper/CompanyLogo';

// import './SubmitInquiry.css'; // Import the CSS file for styling

// const SubmitInquiry = ({ apiClient }) => {
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         emailAddress: '',
//         phoneNumber: '',
//         messageContent: ''
//     });
//     const [submitting, setSubmitting] = useState(false);
//     const [responseMessage, setResponseMessage] = useState('');

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setSubmitting(true);

//         try {
//             const accessToken = String(localStorage.getItem('accessToken'));
//             const response = await apiClient.submit_inquiry(
//               accessToken,
//               formData.firstName,
//               formData.lastName,
//               formData.emailAddress,
//               formData.phoneNumber,
//               formData.messageContent,
//             );
//             setResponseMessage(response.data.message);
//             setFormData({
//                 firstName: '',
//                 lastName: '',
//                 emailAddress: '',
//                 phoneNumber: '',
//                 messageContent: ''
//             });
//         } catch (error) {
//             setResponseMessage('An error occurred while submitting the form.');
//         } finally {
//             setSubmitting(false);
//         }
//     };

//     return (
//       <>
//         {/* <CompanyLogo imageUrl="./hb_logo.png"/> */}
//         <div></div>
//         <div className="submit-inquiry-container">
//           <h2>Submit Inquiry</h2>
//           <form onSubmit={handleSubmit}>
//             <label>First Name</label>
//             <input
//               type="text"
//               name="firstName"
//               placeholder="First Name"
//               value={formData.firstName}
//               onChange={handleChange}
//               required
//             />
//             <label>Last Name</label>
//             <input
//               type="text"
//               name="lastName"
//               placeholder="Last Name"
//               value={formData.lastName}
//               onChange={handleChange}
//               required
//             />
//             <label>Email</label>
//             <input
//               type="email"
//               name="emailAddress"
//               placeholder="Email Address"
//               value={formData.emailAddress}
//               onChange={handleChange}
//               required
//             />
//             <label>Phone No</label>
//             <input
//               type="tel"
//               name="phoneNumber"
//               placeholder="Phone Number"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//               required
//             />
//             <textarea
//               name="messageContent"
//               placeholder="Message Content"
//               value={formData.messageContent}
//               onChange={handleChange}
//               required
//               style={{ resize: "none" }} // Disable textarea resizing
//             ></textarea>
//             <button type="submit" disabled={submitting}>
//               {submitting ? "Submitting..." : "Submit"}
//             </button>
//           </form>
//           {responseMessage && <p>{responseMessage}</p>}
//         </div>
//       </>
//     );
// };

// export default SubmitInquiry;


import React, { useState } from "react";
import "./SubmitInquiry.css";

const SubmitInquiry = ({ apiClient }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    messageContent: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const accessToken = String(localStorage.getItem("accessToken"));
      const response = await apiClient.submit_inquiry(
        accessToken,
        formData.firstName,
        formData.lastName,
        formData.emailAddress,
        formData.phoneNumber,
        formData.messageContent
      );
      setResponseMessage(response.data.message);
      setFormData({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        messageContent: "",
      });
    } catch (error) {
      setResponseMessage("An error occurred while submitting the form.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="submit-inquiry-container">
      <h2>Submit Inquiry</h2>
      <p>Fill out the form below and our team will get back to you shortly.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-row">
            <label htmlFor="firstName">
              <span>*</span> First Name
            </label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <label htmlFor="lastName">
              <span>*</span> Last Name
            </label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <label htmlFor="emailAddress">
              <span>*</span> Email Address
            </label>
            <input
              id="emailAddress"
              type="email"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <label htmlFor="phoneNumber">
              {" "}
              <span>*</span> Phone Number
            </label>
            <input
              id="phoneNumber"
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <label htmlFor="messageContent">
            <span>*</span> Message
          </label>
          <textarea
            id="messageContent"
            name="messageContent"
            value={formData.messageContent}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" disabled={submitting}>
          {submitting ? "Submitting..." : "Submit Inquiry"}
        </button>
      </form>
      {responseMessage && (
        <p className={responseMessage.includes("error") ? "error" : "success"}>
          {responseMessage}
        </p>
      )}
    </div>
  );
};

export default SubmitInquiry;
