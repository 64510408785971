import React, { useEffect , useRef,useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import VideoEmbed from './VideoEmbed';
import ScrollReveal from "scrollreveal";
import Setuplist from './Setuplist';
import FeatureComparisonTable from '../offerings/FeatureComparisonTable';
import './Systemscontroller.css'
import * as THREE from "three";
import NET from "vanta/src/vanta.net"; // Import Vanta.NET


const Systemscontoller = () => {
  const navigate = useNavigate();
  const vantaRef = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(null);

      const handleSearch = (e) => {
        navigate("/universal-shell");
        return;
    };


const loadScript = (src, callback) => {
      console.log(`Loading script: ${src}`);
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = () => {
        console.log(`Loaded: ${src}`);
        callback();
      };
      document.body.appendChild(script);
    };

    useEffect(() => {
      if (!vantaRef.current) return; // Ensure ref is ready

      loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js",
        () => {
          loadScript(
            "https://cdnjs.cloudflare.com/ajax/libs/vanta/0.5.24/vanta.net.min.js",
            () => {
              if (!vantaEffect && window.VANTA) {
                console.log("Initializing Vanta...");
                setVantaEffect(
                  window.VANTA.NET({
                    el: vantaRef.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.0,
                    minWidth: 200.0,
                    scale: 1.0,
                    scaleMobile: 1.0,
                    color: 0xffffff,
                  })
                );
              }
            }
          );
        }
      );

      return () => {
        if (vantaEffect) {
          console.log("Destroying Vanta effect...");
          vantaEffect.destroy();
        }
      };
    }, [vantaRef]);

     useEffect(() => {
      //  ScrollReveal().reveal(".sys-image", {
      //    delay: 300,
      //    origin: "left",
      //    duration: 1000,
      //    distance: "50px",
      //    opacity: 0,
      //    easing: "ease-in-out",
      //  });
       ScrollReveal().reveal(".introduction_h1", {
         delay: 300,
         origin: "left",
         duration: 1000,
         distance: "50px",
         opacity: 0,
         easing: "ease-in-out",
       });
       ScrollReveal().reveal(".cloud-text", {
         delay: 300,
         origin: "bottom",
         duration: 1000,
         distance: "50px",
         opacity: 0,
         easing: "ease-in-out",
       });
      //  ScrollReveal().reveal(".info-container", {
      //    delay: 300,
      //    origin: "bottom",
      //    duration: 1000,
      //    distance: "50px",
      //    opacity: 0,
      //    easing: "ease-in-out",
      //  });

     }, []);
  return (
    <div>
      <section ref={vantaRef} className="introduction_div">
        <div className="introduction_container">

        <div className="introduction_h1">
          <h2 class="hero-title">HypothesisBase Systems Controller</h2>
          <h3 class="hero-subtitle">Deploy & Manage any Software System</h3>
        </div>


          {/* added by me */}
          <div
            style={{
              position: "relative",
              width: "fit-content",
              marginInline: "auto",
              top: "-15%",
              marginBottom: "20px",
            }}
          >
            <img src="mockupnew.png" alt="mockup" />
          </div>
          {/*  */}

          <section style={{ background: "black", width: "100%" }}></section>

        </div>
      </section>
      {/* <div>hfjnjfnjflfnlf</div> */}
      <section className="quick-guide">
        <h2 className="header2">Systems Controller Introduction</h2>
        <p class="intro-text">
          HypothesisBase Systems Controller is a platform-agnostic configuration
          management solution featuring job scheduling, service management, and
          observability, designed for effortless control and peak operational
          efficiency.
        </p>
        <VideoEmbed />
      </section>

      <div>
        <FeatureComparisonTable />
      </div>

      <section className="quick-guide">
        <h2 className="header2">Simple Guide</h2>

        <div className="system-con">
          <div className="sys-image">
            <img src="./workspace 2.png" alt="workspace" />
          </div>
          <div className="sys-text">
            <b>Step 1</b>
            <p>Upload the file or files you want to deploy on the remote machine.</p>
          </div>
        </div>

        <div className="system-con">
          <div className="sys-image">
            <img src="./workflowpic.png" alt="workspace" />
          </div>
          <div className="sys-text">
            <b>Step 2</b>
            <p>Create the workflow file in the "workflows/" directory, then provide the file name for the configurations.</p>
          </div>
        </div>

        <div className="system-con">
          <div className="sys-image">
            <img src="./workflow conf.png" alt="workspace" />
          </div>
          <div className="sys-text">
            <b>Step 3</b>
            <p>Configure the workflow file you created and use the <i>universal-shell API overview</i> to configure it based on your goals. <Link to="/universal-shell/api-overview"><p className="shell-api">universal-shell API overview</p></Link></p>
          </div>
        </div>

        <div className="system-con">
          <div className="sys-image">
            <img src="./exec pic.png" alt="workspace" />
          </div>
          <div className="sys-text">
            <b>Step 4</b>
            <p>Save and navigate to the "Exec" section. Select the "auth_rem.json" and the workflow file containing the configuration for the file you are moving to the remote machine.</p>
          </div>
        </div>

        <div className="system-con">
          <div className="sys-image">
            <img src="./job list.png" alt="workspace" />
          </div>
          <div className="sys-text">
            <b>Step 5</b>
            <p>Navigate to the "Jobs" section and refresh the page until the status shows "Completed."</p>
          </div>
        </div>

        <div className="system-con">
          <div className="sys-image">
            <img src="./new service.png" alt="workspace" />
          </div>
          <div className="sys-text">
            <b>Step 6</b>
            <p>Navigate to the "Services" section and select the "auth_rem.json" file.</p>
          </div>
        </div>

        <div className="system-con">
          <div className="sys-image">
            <img src="./new service action.png" alt="workspace" />
          </div>
          <div className="sys-text">
            <b>Step 7</b>
            <p>Navigate to the service name, select "Action," and start the process. Refresh the page until it shows "Running."</p>
          </div>
        </div>
      </section>


      <Setuplist />
    </div>
  );
}

export default Systemscontoller
