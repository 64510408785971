import React from 'react'
import { Link } from "react-router-dom";
import "./Setuplist.css"
const Setuplist = () => {





  return (
    <div>
      <section class="info-section">
              <h2 className="header2">Documentation </h2>
              <div class="info-container">
                <div class="info-box">
                  <h2>HypothesisBase Universal Shell (H.B.U.S.)</h2>
                  <p>
                    The H.B.U.S. is a simple, scalable, and portable automation tool
                    to solve automation challenges of enterprises managing software
                    systems.
                  </p>

                  <Link to="/universal-shell">
                    <button className="learn-m">Learn more</button>
                  </Link>
                </div>

                <div class="info-box">
                  <h2>AWS Extended Universal Shell (AWS EUS)</h2>
                  <p>
                    The AWS EUS is a simple extension of the Universal Shell. The key
                    distinction is that a node can be identified by the expression:
                  </p>
                  {/* <Link to="/search-jobs" className="nav-link">Jobs</Link>         */}
                  <Link to="/universal-shell-aws">
                    <button className="learn-m">Learn more</button>
                  </Link>
                </div>

                <div class="info-box">
                  <h2>AWS Infrastructure Builder</h2>
                  <p>
                    The AWS Infrastructure Builder is an executable tool designed to
                    provision and manage infrastructure through AWS using a JSON-based
                    language consistent with the official AWS EC2 API.
                  </p>

                  <Link to="/aws-infra-builder">
                    <button className="learn-m">Learn more</button>
                  </Link>
                </div>
              </div>
            </section>
    </div>
  )
}

export default Setuplist
