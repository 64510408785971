import React, { useEffect,useState,useRef } from 'react'
import {  useNavigate } from "react-router-dom";
import ScrollReveal from "scrollreveal";
import "./InfrastructureManage.css"
// import * as THREE from "three";
// import NET from "vanta/src/vanta.net";

const InfrastructureManage = () => {
  const navigate = useNavigate();
  const vantaRef = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(null);

      const handleSearch = (e) => {
        navigate("/aws-infra-builder");
        return;
      };

  const loadScript = (src, callback) => {
        console.log(`Loading script: ${src}`);
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => {
          console.log(`Loaded: ${src}`);
          callback();
        };
        document.body.appendChild(script);
      };
  
      useEffect(() => {
        if (!vantaRef.current) return; // Ensure ref is ready
  
        loadScript(
          "https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js",
          () => {
            loadScript(
              "https://cdnjs.cloudflare.com/ajax/libs/vanta/0.5.24/vanta.net.min.js",
              () => {
                if (!vantaEffect && window.VANTA) {
                  console.log("Initializing Vanta...");
                  setVantaEffect(
                    window.VANTA.NET({
                      el: vantaRef.current,
                      mouseControls: true,
                      touchControls: true,
                      gyroControls: false,
                      minHeight: 200.0,
                      minWidth: 200.0,
                      scale: 1.0,
                      scaleMobile: 1.0,
                      color: 0xffffff,
                    })
                  );
                }
              }
            );
          }
        );
  
        return () => {
          if (vantaEffect) {
            console.log("Destroying Vanta effect...");
            vantaEffect.destroy();
          }
        };
      }, [vantaRef]);

   useEffect(() => {
     ScrollReveal().reveal(".introduction_h1", {
       delay: 350,
       origin: "bottom",
       duration: 1000,
       distance: "50px",
       opacity: 0,
       easing: "ease-in-out",
       // reset: true,
     });

     ScrollReveal().reveal(".cloud-text", {
       delay: 700,
       duration: 300,
       origin: "right",
       distance: "50px",
       opacity: 0,
       easing: "ease-in-out",
       // reset: true,
     });

     ScrollReveal().reveal(".cloud-logos", {
       delay: 1000,
       duration: 350,
       distance: "50px",
       opacity: 0,
       easing: "ease-in-out",
       // reset: true,
     });
   }, []);


  return (
    <div>
      <section ref={vantaRef} className="introduction_div">
        <div className="introduction_container">
          <h1 className="introduction_h1"> Infrastructure Manager for AWS</h1>

          {/* added by me */}
          <div
            style={{
              position: "relative",
              width: "fit-content",
              marginInline: "auto",
              top: "-15%",
              marginBottom:'20px'
            }}
          >
            <img src="mockupnew.png" alt="mockup" />
          </div>
          {/*  */}

          <section style={{ background: "black", width: "100%" }}></section>

          <button className="introduction-search-button" onClick={handleSearch}>
            View Documentation
          </button>
        </div>
      </section>

      <section>
  <div className="infrastructure-container">
    {/* Hero Section */}
    <section className="hero-section">
      <div className="hero-content">
        <h2 className="header2">Infrastructure Manager For AWS</h2>
      </div>
    </section>

    {/* About Section */}
    <section className="about-section">
        Simplify the provisioning and management of resources on Amazon Web Services with a
        drop-in executable solution.
    </section>

    {/* Key Features Section */}
    <section className="features-section">
      <h2>Key Features</h2>
      <div className="features-grid">
        <div className="feature-item">
          <h3>Provision and Manage AWS Infrastructure</h3>
          <p>
            Use the AWS Infrastructure Builder to provision and manage your
            AWS resources with a JSON-based language consistent with the
            official AWS EC2 API.
          </p>
        </div>
        <div className="feature-item">
          <h3>Flexible API Syntax</h3>
          <p>
            The module supports the AWS EC2 API in snake case, but it also
            accepts lower camel case for ease of use and flexibility.
          </p>
        </div>
        <div className="feature-item">
          <h3>Automated Resource Management</h3>
          <p>
            Automate the provisioning and configuration of AWS resources by
            defining actions in a simple JSON format and executing them with
            ease.
          </p>
        </div>
        <div className="feature-item">
          <h3>Programmatically Filter API Responses</h3>
          <p>
            Filter response data programmatically for more control over the
            returned information. Customize how data is retrieved, ensuring
            only the required details are returned from the AWS EC2 API.
          </p>
        </div>
        <div className="feature-item">
          <h3>Cross-Region Support</h3>
          <p>
            Define AWS credentials for each region, allowing you to provision
            and manage resources across multiple AWS regions seamlessly.
          </p>
        </div>
      </div>
    </section>
  </div>
</section>


    </div>
  );
}

export default InfrastructureManage
