import React from 'react'
import "./SystemsController.css"
import { Link } from 'react-router-dom';
// import ScrollReveal from "scrollreveal";
const SystemsController = () => {

//  useEffect(() => {
//    ScrollReveal().reveal(".sys-image", {
//      delay: 300,
//      origin: "left",
//      duration: 1000,
//      distance: "50px",
//      opacity: 0,
//      easing: "ease-in-out",
//    });
//    ScrollReveal().reveal(".sys-text", {
//      delay: 400,
//      origin: "bottom",
//      duration: 800,
//      distance: "30px",
//      opacity: 0,
//      easing: "ease-in-out",
//      interval: 200,
//    });
//  }, []);



  return (
    <div className="control">
      <h2 className="header2">What is the HypothesisBase Systems Controller?</h2>
      <div className="system-con">
        <div className="sys-image">
          <img src="./workspace 2.png" alt="workspace" />
        </div>
        <div className=" sys-text">
          <p>
            The HypothesisBase Systems Controller is a portable, easy-to-use configuration management system.
            It integrates seamlessly with your existing infrastructure at the executable layer,
            and its simple API requires minimal learning.
            Manage configurations with intuitive workflows that enhance system uptime and boost developer agility.
          </p>
          <Link to="/systems-controller">
          <button className='learn-m '>Get Started</button>

          </Link>
        </div>
      </div>
    </div>
  );
}

export default SystemsController
