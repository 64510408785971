import React, { useState, useEffect } from 'react';

function TypingText({ text, typingSpeed }) {
  const [displayText, setDisplayText] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayText(text.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, typingSpeed);

    // Toggle cursor every 500ms
    const cursorIntervalId = setInterval(() => {
      setShowCursor(prevShowCursor => !prevShowCursor);
    }, 500);

    return () => {
      clearInterval(intervalId);
      clearInterval(cursorIntervalId);
    };
  }, [text, typingSpeed]);

  return (
    <div>
      <p>{displayText}

      {showCursor ? <span>|</span> : <span>&nbsp;</span>}
      </p>
    </div>
  );
}

export default TypingText;
