import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/auth/Login';
import RequestRegistration from './components/auth/RequestRegistration';

import ViewProfile from './components/app/profile/ViewProfile';
import EditProfile from './components/app/profile/EditProfile';
import SearchJobs from './components/app/search/SearchJobs';
import ViewJob from './components/app/search/ViewJob';

import Header from './components/app/Header';
import Home from './components/app/product/Home';
import Products from './components/app/product/Products';
import SubmitInquiry from './components/app/product/SubmitInquiry';

import HbusDocs from './components/api_docs/HbusDocs';
import HbusWithAws from './components/api_docs/HbusWithAws';
import AwsInfraBuilder from './components/api_docs/AwsInfraBuilder';
import Footer from './components/app/product/homepage/Footer';
import ApiClient from './structs/ApiClient';
import Purchase from './components/app/product/Purchase/Purchase';
import UserInfo from './components/app/UserInfo/UserInfo';
import Systemscontoller from './components/app/product/SystemsController/Systemscontoller';
import InfrastructureManage from './components/Solutions/InfrastructureManage';
import LLMIntegrations from './components/Solutions/LLMIntegrations';
import Datawarehouseintegration from './components/Solutions/Datawarehouseintegration';

// var endpoint = 'http://127.0.0.1:8085'
const endpoint = 'https://hypothesisbase.com';// Production
// const endpoint = 'http://127.0.0.1:8000';//Staging
var apiClient = new ApiClient(endpoint);

function App() {
  document.title = 'HypothesisBase | Automation Simplified | Innovation Amplified';
  return (
    <Router>
      <Header apiClient={apiClient} />
      <Routes>
        <Route path="/login" element={<Login apiClient={apiClient} />} />

        <Route
          path="/automation-controller"
          element={<HbusDocs apiClient={apiClient} />}
        />
        <Route
          path="/automation-controller/:section"
          element={<HbusDocs apiClient={apiClient} />}
        />
        <Route
          path="/universal-shell"
          element={<HbusDocs apiClient={apiClient} />}
        />
        <Route
          path="/universal-shell/:section"
          element={<HbusDocs apiClient={apiClient} />}
        />
        <Route
          path="/universal-shell-aws"
          element={<HbusWithAws apiClient={apiClient} />}
        />
        <Route
          path="/universal-shell-aws/:section"
          element={<HbusWithAws apiClient={apiClient} />}
        />
        <Route
          path="/aws-infra-builder"
          element={<AwsInfraBuilder apiClient={apiClient} />}
        />
        <Route
          path="/aws-infra-builder/:section"
          element={<AwsInfraBuilder apiClient={apiClient} />}
        />

        <Route
          path="/request-registration"
          element={<RequestRegistration apiClient={apiClient} />}
        />


        <Route
          path="/view-profile"
          element={<ViewProfile apiClient={apiClient} />}
        />
        <Route
          path="/edit-profile"
          element={<EditProfile apiClient={apiClient} />}
        />
        <Route
          path="/search-jobs"
          element={<SearchJobs apiClient={apiClient} />}
        />
        <Route path="/job/:jobId" element={<ViewJob apiClient={apiClient} />} />
        <Route path="/home" element={<Home apiClient={apiClient} />} />
        <Route path="/products" element={<Products apiClient={apiClient} />} />
        <Route
          path="/submit-inquiry"
          element={<SubmitInquiry apiClient={apiClient} />}
        />
        <Route
          path="/contact-us"
          element={<SubmitInquiry apiClient={apiClient} />}
        />
        <Route path="/*" element={<Home apiClient={apiClient} />} />
        <Route path="/Purchase*" element={<Purchase apiClient={apiClient} />} />
        <Route path="/userinfo" element={<UserInfo apiClient={apiClient} />} />
        <Route path="/systems-controller" element={<Systemscontoller apiClient={apiClient} />} />
        <Route path="/Infrastructure-Manager-for-aws" element={<InfrastructureManage apiClient={apiClient} />} />
        <Route path="/LLM-Integrations" element={<LLMIntegrations apiClient={apiClient} />} />
        <Route path="/Data-Warehousing-Integration" element={<Datawarehouseintegration apiClient={apiClient} />} />


      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
